import React, {useContext} from "react";
import ThemeContext from "../../store/themeContext";
import {Link} from "react-router-dom";
import './Employee.css';
import {Icon} from "@iconify/react";

function Employee() {
    const API_URL = process.env.REACT_APP_API_URL;
    const {theme, toggleTheme} = useContext(ThemeContext);
    const userType = sessionStorage.getItem("userType");
    return (
        <div className="data">
            <div className="card" style={{background: 'linear-gradient(145deg,#64b5f6, #2196f3)'}}>
                <Link to='/employees/all'>
                    <div className="title">All Employees</div>
                    <div className="value" style={{fontSize: '18px', fontWeight: 'lighter'}}>Get the list of All
                        Employees
                    </div>
                    <Icon icon="clarity:employee-group-solid" className="icon"/>
                </Link>
            </div>

            <div className="card" style={{background: 'linear-gradient(145deg, #8c6cef,#8d3fed)'}}>
                <Link to='/employees/active'>
                    <div className="title">Active Employees</div>
                    <div className="value" style={{fontSize: '18px', fontWeight: 'lighter'}}>Get the details of Actively Hired Employees</div>
                    <Icon icon="ph:student" className="icon"/>
                </Link>
            </div>

            <div className="card" style={{background: 'linear-gradient(145deg, #59ed38, #08a80f)'}}>
                <Link to='/employees/inactive'>
                    <div className="title">Inactive Employees</div>
                    <div className="value" style={{fontSize: '18px', fontWeight: 'lighter'}}>Get the details of Inactive Employees</div>
                    <Icon icon="bx:windows" className="icon"/>
                </Link>
            </div>
        </div>
    )
}

export default Employee;