import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import ThemeContext from "../../store/themeContext";
import "./LeaveMgmt.css";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";
import { Icon } from "@iconify/react";
import {useNavigate} from "react-router-dom";

const LeaveMgmt: React.FC = () => {
    const [id, setId] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [dol, setDol] = useState<string>("");
    const [category, setCategory] = useState<string>(""); // New category state
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [leaves, setLeaves] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { theme } = useContext(ThemeContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const userType = sessionStorage.getItem("userType");

    const leaveCategories = [
        "Sick Leave",
        "Casual Leave",
        "Annual Leave",
        "Maternity Leave",
        "Paternity Leave",
        "Paid Leave",
        "Unpaid Leave"
    ];

    useEffect(() => {
        const empId = sessionStorage.getItem("EMPID");
        if (empId) {
            setId(empId);
        }

        if (userType === "2") {
            fetchAllLeaves();
        }
    }, [userType]);

    const fetchAllLeaves = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${API_URL}/Admin/Fetch/Leaves_Pending`, {
                headers: {
                    Authorization: "Bearer " + sessionStorage.getItem("token"),
                },
            });

            if (response.data.error === "0") {
                const formattedLeaves = response.data.msg.map((leave: any) => ({
                    ...leave,
                    dol: new Date(leave.dol).toLocaleDateString(),
                    status:
                        leave.status === 0
                            ? "Pending"
                            : leave.status === 1
                                ? "Approved"
                                : leave.status === 2
                                    ? "Rejected"
                                    : "Unknown",
                }));
                setLeaves(formattedLeaves);
            } else {
                setError(response.data.msg || "Failed to fetch leave data.");
            }
        } catch (err: any) {
            const errorMessage =
                err.response?.data?.msg || err.message || "Error fetching leave data.";
            setError(typeof errorMessage === "string" ? errorMessage : JSON.stringify(errorMessage));
        } finally {
            setLoading(false);
        }
    };

    const handleApplyLeave = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!category) {
            setError("Please select a leave category.");
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                `${API_URL}/Employee/Routes/ApplyLeave`,
                { id, reason, dol, category },
                {
                    headers: {
                        Authorization: "Bearer " + sessionStorage.getItem("token"),
                    },
                }
            );

            if (response.data.error === "0") {
                alert("Leave applied successfully!");
                setReason("");
                setDol("");
                setCategory("");
            } else {
                setError(response.data.msg || "An unexpected error occurred.");
            }
        } catch (err: any) {
            const errorMessage =
                err.response?.data?.msg || err.message || "Failed to apply for leave.";
            setError(typeof errorMessage === "string" ? errorMessage : JSON.stringify(errorMessage));
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleCardClick = (route: string) => {
        navigate(route);
    };
    const itemsPerPage = 10;
    const totalPages = Math.ceil(leaves.length / itemsPerPage);
    const currentItems = leaves.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className={`container ${theme}`}>
            {loading ? (
                <LoadingSpinner />
            ) : error ? (
                <p className="error">{error}</p>
            ) : userType === "2" ? (
                    <div className="cards-container">
                        <div className="card" onClick={() => handleCardClick('/leaves/all')}
                             style={{background: 'linear-gradient(145deg,#64b5f6, #2196f3)', cursor: 'pointer'}}>
                            <div className="title">All Requests</div>
                            <div className="value" style={{fontSize: '18px', fontWeight: 'lighter'}}>View all leave requests</div>
                            <Icon icon="clarity:employee-group-solid" className="icon"/>
                        </div>

                        <div className="card" onClick={() => handleCardClick('/leaves/pending')}
                             style={{background: 'linear-gradient(145deg, #8c6cef,#8d3fed)', cursor: 'pointer'}}>
                            <div className="title">Pending Requests</div>
                            <div className="value" style={{fontSize: '18px', fontWeight: 'lighter'}}>View pending leave requests</div>
                            <Icon icon="ph:student" className="icon"/>
                        </div>
                    </div>
            ) : (
                <div className={`assign-task ${theme}`}>
                    <h1>LEAVE MANAGEMENT</h1>
                    <form onSubmit={handleApplyLeave}>
                        <label>
                            Employee ID:
                            <input type="text" value={id} disabled/>
                        </label>

                        <label>
                            Reason for Leave:
                            <textarea
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                                required
                            />
                        </label>

                        <label>
                            Leave Category:
                            <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                                <option value="" disabled>Select Leave Category</option>
                                {leaveCategories.map((cat, index) => (
                                    <option key={index} value={cat}>{cat}</option>
                                ))}
                            </select>
                        </label>

                        <label>
                            Date of Leave:
                            <input
                                type="date"
                                value={dol}
                                onChange={(e) => setDol(e.target.value)}
                                required
                            />
                        </label>

                        <button type="submit">Apply Leave</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default LeaveMgmt;
