import React, { useContext, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useWindowSize } from "usehooks-ts";
import SidebarContext from "../../store/sidebarContext";
import ThemeContext from "../../store/themeContext";
import LoginContext from "../../store/loginContext";
import classes from "./TopNav.module.scss";
import ThemeBox from "./rightBox/themeBox/ThemeBox";
import axios from "axios";

function TopNav() {
    const sideOpenCtx = useContext(SidebarContext);
    const { width } = useWindowSize();
    const { theme } = useContext(ThemeContext);
    const loginCtx = useContext(LoginContext);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
    const userName = sessionStorage.getItem("userType") === '2' ? 'Admin': sessionStorage.getItem("name");

    const notificationIconColor = theme === "dark" ? "white" : "black";

    useEffect(() => {
        async function fetchNotifications() {
            const API_URL = process.env.REACT_APP_API_URL;
            const token = sessionStorage.getItem("token");

            if (!token) {
                console.log("Token is missing");
                return;
            }

            const userType = sessionStorage.getItem("userType");

            // Fetch notifications
            try {
                if (userType === "2") {
                    const response = await fetch(`${API_URL}/Admin/Fetch/getNotification`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });

                    if (!response.ok) throw new Error("Failed to fetch notifications.");

                    const data = await response.json();
                    const unreadNotifications = data.msg.filter((notif: any) => notif.seen === 0);
                    setNotifications(data.msg);
                    setHasUnreadNotifications(unreadNotifications.length > 0);
                } else if (userType === "0" || userType === "1") {
                    const employeeId = sessionStorage.getItem("EMPID");
                    const response = await axios.post(
                        `${API_URL}/Employee/Routes/getNotification`,
                        { id: employeeId },
                        {
                            headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
                        }
                    );

                    const unreadNotifications = response.data.msg.filter((notif: any) => notif.seen === 0);
                    setNotifications(response.data.msg);
                    setHasUnreadNotifications(unreadNotifications.length > 0);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        }

        fetchNotifications();
    }, []);

    function openSidebarHandler() {
        sideOpenCtx.toggleSidebar();
        if (width <= 768) document.body.classList.toggle("sidebar__open");
    }

    function toggleDialog() {
        setIsDialogOpen((prev) => !prev);
    }

    return (
        <div className={classes.topNav}>
            <div className={classes.topNav_left}>
                <div className={classes.topNav_left_menu_icon} onClick={openSidebarHandler}>
                    <Icon icon="ci:menu-alt-03" width="24" />
                </div>
            </div>
            <div onClick={toggleDialog} className={`${classes.notificationIcon} ${hasUnreadNotifications ? classes.alert : ""}`}>
                <Icon
                    icon="mingcute:notification-line"
                    style={{ color: notificationIconColor, height: "30px", width: "30px", cursor: "pointer" }}
                />
            </div>
            {isDialogOpen && (
                <div className={classes.dialogOverlay} onClick={() => setIsDialogOpen(false)}>
                    <div className={classes.dialogBox} onClick={(e) => e.stopPropagation()}>
                        <h3>Notifications</h3>
                        <ul className={classes.notificationList}>
                            {notifications.length > 0 ? (
                                [...notifications].reverse().map((notif, index) => (
                                    <li key={index} className={classes.notificationItem}>
                                        {notif.data}
                                    </li>
                                ))
                            ) : (
                                <p>No new notifications</p>
                            )}
                        </ul>
                        <button className={classes.dialogCloseButton} onClick={() => setIsDialogOpen(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}
            <ThemeBox />
            {userName && <span className={classes.userName} style={{ fontWeight: "bolder" }}>Hi {userName}!</span>}
        </div>
    );
}

export default TopNav;
