import React, { useEffect, useRef, useState } from "react";
import "./UpdateProfile.css";
import { useNavigate } from "react-router-dom";

const UpdateProfile: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        designation: '',
        phone: '',
        bank_acc_no: '',
        ifsc_code: '',
        dob: '',
        bank_name: ''
    });

    const designationRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLInputElement>(null);
    const bankAccNoRef = useRef<HTMLInputElement>(null);
    const ifscRef = useRef<HTMLInputElement>(null);
    const dobRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const employeeData = sessionStorage.getItem("myData");
    const employeeName = employeeData ? JSON.parse(employeeData).name : "Admin";
    const [id, setId] = useState<string>('');

    useEffect(() => {
        const empId = sessionStorage.getItem("EMPID");
        if (empId) {
            setId(empId);
        }
        console.log(id);

        const savedData = employeeData ? JSON.parse(employeeData) : null;
        if (savedData) {
            setFormData({
                designation: savedData.designation || '',
                phone: savedData.phone || '',
                bank_name: savedData.bank_name || '',
                bank_acc_no: savedData?.bank_acc_no || '',
                ifsc_code: savedData.ifsc_code || '',
                dob: savedData.dob || '',
            });
        }
    }, [employeeData]);

    const signUpHandler = async (e: React.FormEvent) => {
        e.preventDefault();

        const designation = designationRef.current?.value;
        const phone = phoneRef.current?.value;
        const bank = bankRef.current?.value;
        const bankAccNo = bankAccNoRef.current?.value;
        const ifsc = ifscRef.current?.value;
        const dob = dobRef.current?.value;

        // Validate required fields
        if (!designation || !phone || !bank || !bankAccNo || !ifsc || !dob || !id) {
            setErrorMessage("All fields are required.");
            return;
        }

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await fetch(`${API_URL}/Employee/Register/EMP_REG_SECOND`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id,  // Ensure that id is included in the request body
                    designation,
                    phone,
                    bank,
                    bankAccNo,
                    ifsc,
                    dob,
                }),
            });

            if (!response.ok) {
                throw new Error("Registration failed. Please try again.");
            }

            const data = await response.json();
            if (data.error === "0")
                navigate("/Dashboard", { state: { myData: { id } } });
            else
                setErrorMessage("Registration failed. Please check your details.");

        } catch (error: any) {
            setErrorMessage(error.message || "Something went wrong. Please try again.");
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setFormData({
            ...formData,
            [field]: e.target.value,
        });
    };

    return (
        <div className="sign-up-two">
            <form onSubmit={signUpHandler}>
                <h2 style={{alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>Update Details</h2>
                {/*<p style={{ fontWeight: 'normal', fontSize: '24px' }}>Employee Name: {employeeName}</p> /!* Display employee name *!/*/}

                <label htmlFor="designation" style={{fontSize: '18px'}}>Designation</label>
                <input
                    id="designation"
                    ref={designationRef}
                    type="text"
                    placeholder="Enter Designation"
                    value={formData.designation}
                    onChange={(e) => handleChange(e, 'designation')}
                />

                <label htmlFor="phone" style={{fontSize: '18px'}}>Phone</label>
                <input
                    id="phone"
                    ref={phoneRef}
                    type="tel"
                    placeholder="Enter Phone Number"
                    value={formData.phone}
                    onChange={(e) => handleChange(e, 'phone')}
                />

                <label htmlFor="bank_name" style={{fontSize: '18px'}}>Bank Name</label>
                <input
                    id="bank_name"
                    ref={bankRef}
                    type="text"
                    placeholder="Enter Bank Name"
                    value={formData.bank_name}
                    onChange={(e) => handleChange(e, 'bank_name')}
                />

                <label htmlFor="bank_acc_no" style={{fontSize: '18px'}}>Bank Account Number</label>
                <input
                    id="bank_acc_no"
                    ref={bankAccNoRef}
                    type="text"
                    placeholder="Enter Bank Account Number"
                    value={formData.bank_acc_no}
                    onChange={(e) => handleChange(e, 'bank_acc_no')}
                />

                <label htmlFor="ifsc_code" style={{fontSize: '18px'}}>IFSC Code</label>
                <input
                    id="ifsc_code"
                    ref={ifscRef}
                    type="text"
                    placeholder="Enter IFSC Code"
                    value={formData.ifsc_code}
                    onChange={(e) => handleChange(e, 'ifsc_code')}
                />

                <label htmlFor="dob" style={{fontSize: '18px'}}>Date of Birth</label>
                <input
                    id="dob"
                    ref={dobRef}
                    type="date"
                    value={formData.dob}
                    onChange={(e) => handleChange(e, 'dob')}
                />

                {errorMessage && <p className="error">{errorMessage}</p>}
                <button type="submit">Submit Details</button>
            </form>
        </div>
    );
};

export default UpdateProfile;
