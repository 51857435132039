import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import ThemeContext from "../../store/themeContext";
import "./LeaveMgmt.css";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import {useNavigate} from "react-router-dom";
import {Icon} from "@iconify/react";

const Leave_Pending: React.FC = () => {
    const [id, setId] = useState<string>("");
    const [reason, setReason] = useState<string>("");
    const [dol, setDol] = useState<string>("");
    const [Category, setCategory] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [leaves, setLeaves] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const {theme} = useContext(ThemeContext);
    const API_URL = process.env.REACT_APP_API_URL;
    const userType = sessionStorage.getItem("userType");
    const navigate = useNavigate();

    const leaveCategories = [
        "Sick Leave", "Casual Leave", "Annual Leave", "Maternity Leave",
        "Paternity Leave", "Paid Leave", "Unpaid Leave"
    ];

    useEffect(() => {
        const empId = sessionStorage.getItem("EMPID");
        if (empId)
            setId(empId);
        fetchAllLeaves();
    }, [userType]);

    const fetchAllLeaves = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${API_URL}/Admin/Fetch/Leaves_Pending`, {
                headers: {Authorization: "Bearer " + sessionStorage.getItem("token")},
            });
            if (response.data.error === "0") {
                setLeaves(response.data.msg.map((leave: any) => ({
                    ...leave,
                    dol: new Date(leave.dol).toLocaleDateString(),
                    status: leave.status === 0 ? "Pending" : leave.status === 1 ? "Approved" : "Rejected",
                })));
            } else {
                setError(response.data.msg || "Failed to fetch leave data.");
            }
        } catch (err: any) {
            setError(err.response?.data?.msg || "Error fetching leave data.");
        } finally {
            setLoading(false);
        }
    };

    const handleLeaveStatus = async (leaveId: number, status: number) => {
        try {
            setLoading(true);
            const response = await axios.post(
                `${API_URL}/Admin/Routes/setLeaveStatus`,
                {id: leaveId, status},
                {
                    headers: {Authorization: "Bearer " + sessionStorage.getItem("token")},
                }
            );
            if (response.data.error === 0) {
                fetchAllLeaves();
            } else {
                setError(response.data.msg || "Failed to update status.");
            }
        } catch (err: any) {
            setError(err.response?.data?.msg || "Error updating leave status.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`container ${theme}`}>
            {loading ? (
                <LoadingSpinner/>
            ) : error ? (
                <p className="error">{error}</p>
            ) : (
                <>
                    <div className="table-title blurred-title">Leave Requests</div>
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Reason</th>
                            <th>Category</th>
                            <th>Date of Leave</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {leaves.map((leave) => (
                            <tr key={leave.id}>
                                <td>{leave.id}</td>
                                <td>{leave.reason}</td>
                                <td>{leave.Category}</td>
                                <td>{leave.dol}</td>
                                <td style={{color: "blueviolet"}}>
                                    {leave.status}
                                </td>
                                <td>
                                    {leave.status === "Pending" && (
                                        <>
                                            <button onClick={() => handleLeaveStatus(leave.id, 1)} style={{color: 'green',border: 'none', cursor: 'pointer', background: 'transparent',padding: '5px',marginRight: '25px',minWidth: 'auto',maxWidth: '30px'}}
                                                    title="Approve Request">
                                                <Icon icon="dashicons:yes" width="27px" height="27px"/></button>
                                            <button onClick={() => handleLeaveStatus(leave.id, -1)} style={{color: 'green',border: 'none', cursor: 'pointer', background: 'transparent',padding: '5px',minWidth: 'auto',maxWidth: '30px'}}
                                                    title="Reject Request">
                                                <Icon icon="basil:cross-outline" width="35px" height="35px" color='red'/></button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Pagination currentPage={currentPage} totalPages={Math.ceil(leaves.length / 10)}
                                onPageChange={setCurrentPage}/>
                </>
            )}
        </div>
    )

};

export default Leave_Pending;
