import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { images } from "../../constants";
import sidebarNav from "../../config/sidebarNav";
import SidebarContext from "../../store/sidebarContext";
import LoginContext from "../../store/loginContext";
import { Icon } from "@iconify/react";
import classes from "./Sidebar.module.scss";

const Sidebar = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { width } = useWindowSize();
    const location = useLocation();
    const sidebarCtx = useContext(SidebarContext);
    const loginCtx = useContext(LoginContext);

    const openSidebarHandler = () => {
        if (width <= 768) {
            document.body.classList.toggle("sidebar__open");
        } else {
            sidebarCtx.toggleSidebar();
        }
    };

    const logoutHandler = () => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("myData");
        sessionStorage.removeItem("name");
        sessionStorage.removeItem("userType");
        sessionStorage.removeItem("EMPID");
        loginCtx.toggleLogin();
    };

    useEffect(() => {
        const curPath = window.location.pathname.split("/")[1];
        const activeItem = sidebarNav.findIndex((item) => item.section === curPath);
        setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    }, [location]);

    const userType = loginCtx.userType;
    return (
        <div className="main-content">
            <div className={`${classes.sidebar} ${!sidebarCtx.isOpen && classes.sidebar_close}`}>
                <div className={classes.sidebar__logo}>
                    <Link to="/Dashboard">
                        <img src={images.logo} alt="IITK" />
                    </Link>
                </div>

                <div className={classes.sidebar__menu}>
                    {sidebarNav
                        .filter((nav) => {
                            if (userType === "2") return true;
                            else if ((userType === "0" || userType === "1") && !nav.adminOnly)
                                return true;
                            return false;
                        })
                        .map((nav, index) => (
                            <Link
                                key={`nav-${index}`}
                                to={nav.link || "#"}
                                className={`${classes.sidebar__menu__item} ${activeIndex === index && classes.active}`}
                            >
                                <div className={classes.sidebar__menu__item__icon}>
                                    <Icon icon={nav.icon} />
                                </div>
                                <div className={classes.sidebar__menu__item__txt}>{nav.section}</div>
                            </Link>
                        ))}
                </div>

                {/*Logout component*/}
                <div className={[classes.sidebar__menu, classes.logout].join("")}>
                    <Link to="/login" className={classes.sidebar__menu__item} onClick={logoutHandler}>
                        <div className={classes.sidebar__menu__item__icon}>
                            <Icon icon="tabler:logout" />
                        </div>
                        <div className={classes.sidebar__menu__item__txt}>{"Logout"}</div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
