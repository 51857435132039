import React, { useContext, useState } from "react";
import "./Upload.css";
import ThemeContext from "../../store/themeContext";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/UI/loadingSpinner/LoadingSpinner";
import axios from "axios";

const Upload: React.FC = () => {
    const [files, setFiles] = useState<{ [key: string]: File | null }>({
        photo: null,
        aadhar: null,
        bank_passbook: null,
        marksheet_10: null,
        marksheet_12: null,
        grad_marksheet: null,
        pg_marksheet: null,
        pan_id: null,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = event.target;
        const file = files ? files[0] : null;
        setFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage(null);

        const formData = new FormData();

        for (const key in files) {
            if (files[key]) {
                formData.append(key, files[key]!);
            }
        }

        const employeeId = sessionStorage.getItem("EMPID");
        if (!employeeId) {
            setErrorMessage("Employee ID is missing.");
            setLoading(false);
            return;
        }

        formData.append("id", employeeId);

        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const response = await axios.post(
                `${API_URL}/Employee/Register/upload`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.error === "0") {
                navigate("/Dashboard");
            } else {
                setErrorMessage("Upload failed. Please check your details.");
            }
        } catch (error: any) {
            setErrorMessage(error.response?.data?.message || "Something went wrong. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`profile-page ${theme}`}>
            <div className="main-content">
                <div className="sign-up-two">
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <h2 className='title'>Upload Documents</h2>
                            {errorMessage && <p className="error">{errorMessage}</p>}
                            <div className="file-upload-container">
                                {[
                                    { label: "Profile Photo", name: "photo" },
                                    { label: "Aadhar Card", name: "aadhar" },
                                    { label: "Bank Passbook", name: "bank_passbook" },
                                    { label: "10th Marksheet", name: "marksheet_10" },
                                    { label: "12th Marksheet", name: "marksheet_12" },
                                    { label: "Graduate Marksheet", name: "grad_marksheet" },
                                    { label: "Postgraduate Marksheet", name: "pg_marksheet" },
                                    { label: "PAN ID", name: "pan_id" },
                                ].map((field) => (
                                    <div key={field.name} className="file-input-row">
                                        <label className="file-label">{field.label}</label>
                                        <input className="file-input" type="file" name={field.name} accept="image/*" onChange={handleFileChange} />
                                    </div>
                                ))}
                            </div>
                            <button type="submit">Upload Documents</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Upload;
