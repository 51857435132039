import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";
import InactiveEmployees from "./pages/Employees/InactiveEmployees";
import ActiveEmployees from "./pages/Employees/ActiveEmployees";
import AllEmployees from "./pages/Employees/AllEmployees";
import EditEmployee from "./components/edit/Edit Employee/EditEmployee";
import EmployeeDetails from "./components/EmployeeDetails/EmployeeDetails";
import EditEmployeeDetails from "./components/edit/Edit Employee/EditEmployee";
import SignUp from "./pages/Sign Up/SignUp";
import PendingApproval from "./pages/Pending Approvals/PendingApproval";
import ViewAttendance from "./components/ViewAttendance/ViewAttendance";
import MarkAttendance from "./components/MarkAttendance/MarkAttendance";
import UpdateProfile from "./pages/Update Profile/Update Profile";
import UploadDocs from "./pages/Upload Docs/UploadDocs";
import AssignTask from "./pages/Tasks/AssignTask";
import LeaveMgmt from "./pages/LeaveMgmt/LeaveMgmt";
import Employee from "./pages/Employees/Employee";
import All_Leaves from "./pages/LeaveMgmt/All_Leaves";
import Pending_Leaves from "./pages/LeaveMgmt/Pending_Leaves";

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Projects = React.lazy(() => import("./pages/Tasks/AssignedTasks"));
const Attendance = React.lazy(() => import("./pages/Attendance/Attendance"));
const Holidays = React.lazy(() => import("./pages/Holidays/Holiday"));
const Login = React.lazy(() => import("./pages/Login/Login"));

function App() {
    const [token, setToken] = useState<string | null>(sessionStorage.getItem("token"));

    useEffect(() => {
        const handleStorageChange = () => {
            setToken(sessionStorage.getItem("token"));
        };

        window.addEventListener("storage", handleStorageChange);
        handleStorageChange();

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);
    useEffect(() => {
        setToken(sessionStorage.getItem("token"));
    }, [sessionStorage.getItem("token")]);

    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />

                    {token ? (
                        <Route element={<AuthLayout />}>
                            <Route path="/" element={<MainLayout />}>
                                <Route index element={<Navigate to="/Dashboard" replace />} /> {/* Default redirect */}
                                <Route path="/Dashboard" element={<Dashboard />} />
                                <Route path="/employees/all" element={<AllEmployees />} />
                                <Route path="/employees" element={<Employee />} />
                                <Route path="/employees/active" element={<ActiveEmployees />} />
                                <Route path="/employees/inactive" element={<InactiveEmployees />} />
                                <Route path="/update_profile" element={<UpdateProfile />} />
                                <Route path="/upload" element={<UploadDocs />} />
                                <Route path="/employee/:id" element={<EditEmployee />} />
                                <Route path="/projects" element={<Projects />} />
                                <Route path="/employee/details" element={<EmployeeDetails />} />
                                <Route path="/task_assign" element={<AssignTask />} />
                                <Route path="/attendance" element={<Attendance />} />
                                <Route path="/leave_mgmt" element={<LeaveMgmt />} />
                                <Route path="/leaves/all" element={<All_Leaves />} />
                                <Route path="/leaves/pending" element={<Pending_Leaves />} />
                                <Route path="/employee/attendance/:employeeId" element={<ViewAttendance />} />
                                <Route path="/Mark_Attendance/:id" element={<MarkAttendance />} />
                                <Route path="/EMP_EDIT_DETAILS" element={<EditEmployeeDetails />} />
                                <Route path="/holidays" element={<Holidays />} />
                                <Route path="/appr_pending" element={<PendingApproval />} />
                            </Route>
                        </Route>
                    ) : (
                        !token &&
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    )}
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
